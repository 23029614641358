import * as React from 'react';

import Cloud from '../components/cloud/PurpleCloud';

const NotFoundPage = () => (
    <main className="flex wrap justify-center align-center spacing--small">
        <Cloud />
        <h1 className="h1 relative large full center-text">404</h1>
        <h2 className="h2 relative full center-text">page not found</h2>
    </main>
);

export default NotFoundPage;
