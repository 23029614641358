import React from 'react';
import { Parallax } from 'react-scroll-parallax';
import { StaticImage } from 'gatsby-plugin-image';
import { useViewport } from '@src/contexts/Viewport';

// styles
import './cloud.scss';

export default function PurpleCloud() {
    const { isMobile } = useViewport();

    return (
        <Parallax y={['250px', '-175px']} slowerScrollRate={false} disabled={!!isMobile}>
            <StaticImage
                src="../../images/cloud-purple.png"
                alt="purple cloud"
                className="cloud cloud--up"
                loading="eager"
                fixed="fixed"
                layout="fullWidth"
                placeholder="blurred"
            />
        </Parallax>
    );
}
